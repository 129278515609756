// Separate file to prevent circular dependencies
export const INVITE_FITTER = 'INVITE_FITTER';
export const FETCH_FITTERS = 'FETCH_FITTERS';
export const DELETE_FITTER = 'DELETE_FITTER';
export const UPDATE_USER_SETTINGS = 'UPDATE_USER_SETTINGS';
export const UPDATE_USER_BRAND_SETTINGS = 'UPDATE_USER_BRAND_SETTINGS';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_FITTER = 'UPDATE_FITTER';
export const FETCH_MY_RESULTS_LIST = 'FETCH_MY_RESULTS_LIST';
export const CLEAR_FITTER_CACHE = 'CLEAR_FITTER_CACHE';
export const GET_MY_FITTING_CODES_LIST = 'GET_MY_FITTING_CODES_LIST';
export const GET_FITTER_FITTING_CODE = 'GET_FITTER_FITTING_CODE';
export const SHARE_VOUCHER = 'SHARE_VOUCHER';
export const GET_SUBSCRIPTIONS_LIST = 'GET_SUBSCRIPTIONS_LIST';
export const ADMIN_CREATE_VOUCHER = 'ADMIN_CREATE_VOUCHER';
