import React, { Component } from 'react';
import SendFreeVoucherVoucher from './SendFreeVoucherForm';

class ManageVouchers extends Component {

  render() {
    return (
      <div className="container">
        <div className="invite-user-form">
          <div className="invite-user-form__container">
            <div className="add-user--actions-bar__wrapper">
              <SendFreeVoucherVoucher />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ManageVouchers;
