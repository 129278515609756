import React, { useState } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import ValidationError from '../../common/forms/ValidationError';
import { adminCreateVoucher } from '../../user/user-actions';
import LocalizedStrings from 'react-localization';
import Button from '../../common/components/Button';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import Modal from '../../common/components/Modal';


const SendFreeVoucherForm = (props) => {
  const { dispatch, initialFittingType } = props;
  const [fittingType, setFittingType] = useState(initialFittingType ?? 'basic');
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [formValues, setFormValues] = useState({});
  const [resetForm, setResetForm] = useState(null);
  const [setStatus, setSetStatus] = useState(null);
  const showFittingOptions = false;

  let strings = new LocalizedStrings({
    en: {
      invalidEmail: 'Invalid email',
      sendVoucher: 'Submit',
    },
  });

  const fittingTypeMap = {
    'premium': '5 Fittings Package',
    'standard': '3 Fittings Package',
    'basic': 'Single Fitting Package',
  };

  const handleFittingTypeChange = (event) => {
    setFittingType(event.target.value);
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email(strings.invalidEmail)
      .required(),
    fittingType: Yup.string()
      .required('Fitting type is required'),
  });

  const handleSubmit = (values, { resetForm, setStatus }) => {
    if (!values.fittingType) {
      setStatus('Fitting type is required');
      return;
    }

    setFormValues(values);
    setResetForm(() => resetForm);
    setSetStatus(() => setStatus);
    setConfirmationModalOpen(true);
  };

  const handleConfirmSubmit = () => {
    if (resetForm && setStatus) {
      resetForm();
      return dispatch(adminCreateVoucher({email: formValues.email, fittingType})).then(action => {
        if (!action.json.success) {
          toast('Error submitting voucher!', { 
            type: 'error', 
            position: toast.POSITION.TOP_CENTER 
          });
        } else{
          toast('Voucher submitted successfully!', { 
            type: 'success', 
            position: toast.POSITION.TOP_CENTER 
          });
        }
      });
    }
  };

  return (
    <>
      <Formik
        initialValues={{ email: '', fittingType: fittingType }}
        validationSchema={validationSchema}
        validateOnBlur={false}
        onSubmit={handleSubmit}
      >
        {({ values, errors, isSubmitting, status }) => (
          <Form className="add-user-field--form__wrapper">
            <div className="add-user-field__container">
              <div className="add-user-field__container--flex">
                <div className={classNames('input-scaffold', { error: Object.keys(errors).length > 0 })}>
                <h1>Submit free fitting codes</h1>
                  <div style={{ paddingBottom: '20px' }}>
                    {'The fitting code will be available on the account associated with the email address you provide.'}
                  </div>
                  <Field
                    className="add-user--email"
                    type="email"
                    name="email"
                    placeholder="Enter recipient's email"
                  />
                  {showFittingOptions && (
                    <div className="fitting-options" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      <label style={{ marginRight: '30px', display: 'flex', alignItems: 'center' }}>
                          <input
                            type="radio"
                            value="basic"
                            checked={fittingType === 'basic'}
                            onChange={handleFittingTypeChange}
                          />
                          <span style={{ marginLeft: '5px', marginRight: '10px', whiteSpace: 'nowrap' }}>{ 'Single Fitting' }</span>
                      </label>
                      <label style={{ marginRight: '30px', display: 'flex', alignItems: 'center' }}>
                          <input
                            type="radio"
                            value="standard"
                            checked={fittingType === 'standard'}
                            onChange={handleFittingTypeChange}
                          />
                          <span style={{ marginLeft: '5px', marginRight: '10px', whiteSpace: 'nowrap' }}>{ '3 Fittings' }</span>
                      </label>
                      <label style={{ marginRight: '30px', display: 'flex', alignItems: 'center' }}>
                        <input
                          type="radio"
                          value="premium"
                          checked={fittingType === 'premium'}
                          onChange={handleFittingTypeChange}
                        />
                        <span style={{ marginLeft: '5px', marginRight: '10px', whiteSpace: 'nowrap' }}>{ '5 Fittings' }</span>
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <ErrorMessage name="email" component={ValidationError} />
              {status && <ValidationError>{status}</ValidationError>}
              <Button
                  className="button button--color--primary"
                  textTransform="none"
                  buttonStyle="primary"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {strings.sendVoucher}
                </Button>
            </div>
          </Form>
        )}
      </Formik>

      {isConfirmationModalOpen && (
        <Modal
          className="confirmation-modal"
          isOpened={isConfirmationModalOpen}
          onClose={() => {
            setConfirmationModalOpen(false); 
            resetForm();
          }}
          title="Confirm Submission"
          shouldCloseOnOutsideClick={false}
        >
          <div>
            <p style={{ paddingBottom: '25px' }}>
              The <strong>{fittingTypeMap[fittingType]}</strong> will be available to <strong>{formValues.email}</strong> shortly.
            </p>
            <div className="modal-buttons">
              <button
                className="button button--color--primary"
                onClick={() => {
                  handleConfirmSubmit();
                  setConfirmationModalOpen(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default compose(connect(state => ({})))(SendFreeVoucherForm);
