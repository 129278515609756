import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import LocalizedStrings from 'react-localization';
import LoadingIndicator from '../../../../common/components/LoadingIndicator';
import BallFlightChart from './BallFlightChart';
import { NavLink, withRouter } from 'react-router-dom';

const strings = new LocalizedStrings({
  en: {
    selectTwoBallsToCompare: 'Select two balls to compare:',
    disclaimer: `You assume any risk associated with your use of Ballnamic and recommendations or results provided by Ballnamic or your retailer. Ballnamic results and precision flight simulations are provided for informational purposes only. Actual results may vary.`,
    trademarkStatement: `Ballnamic is a trademark of Karsten Manufacturing Corporation in the U.S. and other countries. All third party trademarks are the property of their respective owners, and use of such trademarks does not imply any affiliation with or endorsement by them.`,
    driverFlight: 'Driver Flight',
    ironFlight: '7-Iron Flight',
    driverFlightIntoWind: 'Driver Flight Into Wind',
    ironFlightIntoWind: '7-Iron Flight Into Wind',
  },
});

class Explore extends Component {
  static propTypes = {
    results: PropTypes.instanceOf(Immutable.List).isRequired,
    flights: PropTypes.instanceOf(Immutable.List),
    questionnaireValues: PropTypes.instanceOf(Immutable.Map),
    favoritedBall: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = this.getInitialState(props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.results !== this.props.results || prevProps.favoritedBall !== this.props.favoritedBall) {
      this.setState(this.getInitialState(this.props));
    }
  }

  getSelectedFlights() {
    const { flights, results } = this.props;

    const selectedBallNames = [];

    this.state.selectedBalls.forEach(index => {
      selectedBallNames.push(results.getIn([index, 'info', 'Ball']));
    });

    let shotType;
    switch (this.props.match.params.shotType) {
      case 'driver':
        shotType = 'DriverNoWind';
        break;
      case 'driver-into-wind':
        shotType = 'DriverWind';
        break;
      case 'iron':
        shotType = 'IronNoWind';
        break;
      case 'iron-into-wind':
        shotType = 'IronWind';
        break;
      default:
        shotType = 'DriverNoWind';
    }

    const selectedFlights = flights.filter(
      flight => selectedBallNames.indexOf(flight.getIn(['Ball', 0])) > -1 && flight.getIn(['ShotType', 0]) === shotType
    );

    return selectedFlights;
  }

  getSelectedBallInfo() {
    const { results } = this.props;

    const info = [];

    this.state.selectedBalls.forEach(index => {
      info.push(results.getIn([index, 'info']));
    });

    return Immutable.List(info);
  }

  getInitialState(props) {
    const selectedBalls = [0];

    if (props.favoritedBall) {
      const favoritedBallIndex = props.results.findIndex(
        result => result.getIn(['info', 'Ball']) === props.favoritedBall
      );
      if (favoritedBallIndex > 0) {
        selectedBalls.push(favoritedBallIndex);
      } else {
        // If favorited ball is the best ball, also select the next best
        selectedBalls.push(1);
      }
    }

    return { selectedBalls };
  }

  isCurrentBall(result) {
    if (!this.props.questionnaireValues) {
      return null;
    }
    const { year, brand, model } = this.props.questionnaireValues.toJS();
    return result.getIn(['display', 'Ball']) === `${year} ${brand} ${model}`;
  }

  renderBallCheckboxes() {
    const { results } = this.props;

    return (
      <div className="explore--ball-checkboxes__wrapper">
        <h5>{strings.selectTwoBallsToCompare}</h5>
        <div className="compare-options__wrapper">
          {results.map((result, index) => (
            <div key={result.getIn(['display', 'Ball'])}>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={this.state.selectedBalls.indexOf(index) > -1}
                  onChange={() => {
                    this.setState(prevState => {
                      const selectedBalls = prevState.selectedBalls.slice();
                      if (selectedBalls.indexOf(index) > -1) {
                        if (selectedBalls.length > 1) {
                          return { selectedBalls: selectedBalls.filter(v => v !== index) };
                        }
                      } else {
                        selectedBalls.push(index);
                        return {
                          selectedBalls,
                        };
                      }
                      return null;
                    });
                  }}
                  disabled={this.state.selectedBalls.length > 1 && this.state.selectedBalls.indexOf(index) === -1}
                />
                <span>
                  {result.getIn(['display', 'DisplayBall'])} {index === 0 ? '(Best Ball)' : null}{' '}
                  {this.isCurrentBall(result) ? '(Current Ball)' : null}
                </span>
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }

  renderShotTypeTabs() {
    return (
      <div className="chart-visualizer--nav">
        <NavLink to="/my-fitting/results/explore/driver">{strings.driverFlight}</NavLink>
        <NavLink to="/my-fitting/results/explore/driver-into-wind">{strings.driverFlightIntoWind}</NavLink>
        <NavLink to="/my-fitting/results/explore/iron">{strings.ironFlight}</NavLink>
        <NavLink to="/my-fitting/results/explore/iron-into-wind">{strings.ironFlightIntoWind}</NavLink>
      </div>
    );
  }

  render() {
    if (!this.props.flights) {
      return <LoadingIndicator />;
    }

    return (
      <div className="explore__wrapper">
        {this.renderBallCheckboxes()}
        <div className="chart-visualizer">
          {this.renderShotTypeTabs()}
          {this.props.match.params.shotType === 'driver-into-wind' && (
            <h4 className="into-wind--header">Simulated 30mph headwind</h4>
          )}
          {this.props.match.params.shotType === 'iron-into-wind' && (
            <h4 className="into-wind--header">Simulated 30mph headwind</h4>
          )}
          <BallFlightChart
            flights={this.getSelectedFlights()}
            ballInfo={this.getSelectedBallInfo()}
            shotType={this.props.match.params.shotType}
          />
        </div>
        <div className="explore--disclaimer">
          <p>{strings.disclaimer}</p>
          <br />
          <p>{strings.trademarkStatement}</p>
        </div>
      </div>
    );
  }
}

export default withRouter(Explore);
